<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link :to="{ name: 'admin.training.add' }" class="btn btn-add" v-if="!isLoading && checkPermissions(['academy_training.view'])">
          <span>{{ 'Training toevoegen' }}</span></router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane" :class="{'loading': isLoading}">
        <div class="table-wrapper">
          <div class="table-element">
            <table class="trainings" v-if="!isLoading">
              <thead>
              <tr>
                <th :class="(sortBy === 'id' ? 'active ' + sortDir : '')" @click="sortTable('id')" class="id">
                  <div>ID</div>
                </th>
                <th>
                  <div>Title</div>
                </th>
                <th :class="(sortBy === 'created' ? 'active ' + sortDir : '')" @click="sortTable('created')">
                  <div>Aangemaakt</div>
                </th>
                <th :class="(sortBy === 'published' ? 'active ' + sortDir : '')" @click="sortTable('published')">
                  <div>Gepubliceerd</div>
                </th>
              </tr>
              </thead>

              <tbody v-if="trainings.length > 0">
              <tr @click="$router.push({name: 'admin.training', params: {id: training.id}})" :key="training.id" v-for="training in trainings">
                <td class="id">{{ training.id }}</td>
                <td>{{ getTranslation(training).title }}</td>
                <td>{{ training.created.setLocale('nl').toFormat('dd/LL/yyyy') }}</td>
                <td>
                  <img width="15" v-if="training.published" src="@/assets/img/check.svg" alt="check">&nbsp;
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ total.min }} - {{ total.max }} van {{ totalTrainings }} trainingen
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">Prev</button>
              <button class="btn-inverse" :disabled="trainings.length !== perPage" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'
import { DateTime } from 'luxon'

export default {
  components: { PageTitle },
  data: function () {
    return {
      isLoading: true,
      search: '',
      trainings: [],
      page: 0,
      perPage: 25,
      sortBy: 'created',
      sortDir: 'asc',
      totalTrainings: 0
    }
  },
  async mounted () {
    this.isLoading = true
    this.getTrainings()
    this.isLoading = false
  },
  methods: {
    async getTrainings () {
      await ApiService.fetchTrainings({
        page: this.page,
        order_by: this.sortBy,
        order_dir: this.sortDir
      }).then(res => {
        this.trainings = res.data.trainings
        this.trainings.map(training => {
          training.created = DateTime.fromISO(training.created)
          return training
        })
        this.totalTrainings = res.data.total
      })
    },
    async sortTable (sortBy) {
      if (sortBy === this.sortBy) {
        this.sortDir = (this.sortDir === 'asc' ? 'desc' : 'asc')
      }

      this.sortBy = sortBy

      await this.getTrainings()
    },
    prevPage: async function () {
      if (this.page > 0) {
        this.page--
        await this.getTrainings()
      }
    },
    nextPage: async function () {
      if (this.trainings.length === this.perPage) {
        this.page++
        await this.getTrainings()
      }
    }
  },
  computed: {
    total: function () {
      const offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      return {
        min: offsetMin,
        max: offsetMax > this.totalTrainings ? this.totalTrainings : offsetMax
      }
    }
  }
}
</script>
